import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// utils
import { bankFilter, contentFilter, dateFilter } from '../../utils/textFilter';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import AccountDetailModal from '../../components/GatheringComponents/ModalComponents/AccountDetailModal';
import CreateAccountModal from '../../components/GatheringComponents/ModalComponents/CreateAccountModal';

import { GatheringAccountInfoFilter } from '../../dataSystems/PageFilters/GatheringAccountInfoFilter';
import { adminUsers } from '../../dataSystems/AdminUserLists';

// designComponents
import MainTable from '../../designComponents/tables/MainTable';
import ContentText from '../../designComponents/text/ContentText';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { FilterToggleButton } from '../../designComponents/buttons/FilterToggleButton';
import { HoverSpan } from '../../designComponents/tables/TbodyLists';
import { MainButton } from '../../designComponents/buttons/Buttons';
import UserDetailModal from '../../components/UserComponents/UserDetailModal';
import TableFilter from '../../designComponents/filters/TableFilter';
import { getGatheringAccountListRequest } from '../../api/main/gathering/GatheringAccountRequest';

/** 계좌정보 */
const GatheringAccountInfo = () => {
    const navigator = useNavigate();
    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        orderType: 1,
        orderByType: 1,
        infoType: [1, 2, 3, 4, 5],
        authType: [1],
    });

    const [open, setOpen] = useState(false); // 계좌정보 있는것만 보기
    const [createAccount, setCreateAccount] = useState(false); // 계좌등록 토글
    const [accountDetail, setAccountDetail] = useState([false, 0]);

    const ref = useRef(null);
    const tableRef = useRef(null);

    /** 계좌인증 */
    const onClickPayout = (user_personal_account_id, account_bank, gathring_account_no) => async () => {
        try {
            if (!account_bank) return alert('[403] 은행코드 존재하지 않습니다.');
            if (!gathring_account_no) return alert('[403] 계좌번호가 존재하지 않습니다.');
            if (window.confirm('계좌인증을 진행하시겠습니까?')) {
                const response = await RefreshCheckApi.patch('/adminGathering/account/auth', {
                    user_personal_account_id: user_personal_account_id,
                });
                if (response.status === 200) {
                    alert(response.data.message);
                    callApi();
                }
            }
        } catch (error) {
            console.error('err: ', error.response.data);
            alert(`[관리자 문의] ${error.response.data.message}`);
        }
    };

    /** 회원 상세정보 */
    const onClickShowUserInfoDetail = (user_id) => () => {
        setUserDetailToggle({ toggle: true, userId: user_id });
    };

    const onClickDetailAccount = (item) => () => {
        navigator(`/gathering/accountInfo/${item.gathering_id}`);
    };

    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            accountInfo: open ? 1 : 0,
            ...param,
        };
        const response = await getGatheringAccountListRequest(input);
        return response.data;
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            accountInfo: open ? 1 : 0,
            ...param,
        };
        const response = await getGatheringAccountListRequest(input);
        return response.all_cnt;
    };

    const onClickCreateAccount = () => setCreateAccount(true);
    const onChange = (checked) => setOpen(checked);
    const onClickItem = (gathering_id) => () => setAccountDetail([true, gathering_id]);

    return (
        <div>
            {createAccount && <CreateAccountModal setCreateAccount={setCreateAccount} />}
            {accountDetail[0] && <AccountDetailModal accountDetail={accountDetail} setAccountDetail={setAccountDetail} />}
            {userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '모임명', value: 1 },
                    { id: 2, title: '모임장명', value: 2 },
                    { id: 3, title: '모임장 닉네임', value: 3 },
                    { id: 4, title: '모임장 휴대폰번호', value: 4 },
                    { id: 5, title: '모임 ID', value: 5 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <TableFilter
                param={param}
                setParam={setParam}
                wrapperStyle={{ width: '100%' }}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [
                            { name: '생성일', width: 100, type: 1 },
                            { name: '인증 완료 시간', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderByType = type;
                            else delete obj.orderByType;
                            setParam(obj);
                        },
                        orderbyType: param.orderByType,
                    },

                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림차순', width: 100, type: 1 },
                            { name: '오름차순', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderType = type;
                            else delete obj.orderType;
                            setParam(obj);
                        },
                        orderbyType: param.orderType,
                    },
                    {
                        filterText: '계좌 유형',
                        orderLists: [
                            { name: '개인 계좌', width: 100, type: 1 },
                            { name: '법인 계좌', width: 100, type: 2 },
                            { name: '미 입력', width: 100, type: 3 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.accountType = type;
                            else delete obj.accountType;
                            setParam(obj);
                        },
                        orderbyType: param.accountType,
                    },
                ]}
                filterLists={[
                    {
                        key: 'infoType',
                        value: '입력 정보',
                        filter: [
                            {
                                key: 1,
                                value: '이름/ 법인명',
                                width: '100px',
                            },
                            {
                                key: 2,
                                value: '주민 번호/ 사업자 등록번호',
                                width: '100px',
                            },
                            {
                                key: 3,
                                value: '은행',
                                width: '100px',
                            },
                            {
                                key: 4,
                                value: '계좌 번호',
                                width: '100px',
                            },
                            {
                                key: 5,
                                value: '예금주',
                                width: '100px',
                            },
                        ],
                    },
                    {
                        key: 'authType',
                        value: '인증 상태',
                        filter: [
                            {
                                key: 0,
                                value: '인증 미완료',
                            },
                            {
                                key: 1,
                                value: '인증 대기중',
                            },
                            {
                                key: 2,
                                value: '인증 완료',
                            },
                        ],
                    },
                ]}
                research={ref}
            />

            <MainTable
                buttonComponents={() => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <FilterToggleButton onChange={onChange} checked={open} text={'입력된 계좌정보만 보기'} />
                        <TopSideButton middleOn={false} rightOn={false} leftWidth={90} text01={'계좌 등록'} onClick={onClickCreateAccount} />
                    </FlexMainWrapper>
                )}
                filterKey={'interest_code'}
                filterItem={(item) => <MainButton text={item.value} onClick={() => {}} />}
                tableStyle={{ width: '95%' }}
                ref={ref}
                tableRef={tableRef}
                loadingText={'계좌정보 리스트를 불러오고 있습니다.'}
                headerData={GatheringAccountInfoFilter}
                checkID={'pay_id'}
                param={param}
                toggleBlur={true}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                adminCheck={(item) => adminUsers.includes(item.user_phone_no) && { color: 'rgb(255, 16, 125)' }}
                onClickRowItem={(item, key) => {
                    // if (key === 'gathering_name') return;
                    // else if (key === 'auth_yn') return;
                    // else if (key === 'user_name') return;
                    // else if (key === 'account_no') return;
                    // else if (key === 'account_holder') return;
                    // else  return onClickDetailAccount(item);
                }}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'business_yn') {
                        return value ? '법인 계좌' : value !== null ? '개인 계좌' : '미입력';
                    } else if (key === 'updated_at') {
                        const requestAuthed = !item.auth_yn && item.license_number;
                        if (requestAuthed) {
                            return dateFilter(value);
                        } else {
                            return '-';
                        }
                    }
                    if (key === 'account_bank') {
                        const bankCode = bankFilter(value);
                        return bankFilter(value)?.KOR;
                    }
                    if (key === 'user_name') return <HoverSpan onClick={onClickShowUserInfoDetail(item.user_id)}>{value}</HoverSpan>;
                    if (key === 'gathering_name') return <HoverSpan onClick={onClickItem(item.gathering_id)}>{contentFilter(value, 20)}</HoverSpan>;
                    if (key === 'auth_yn')
                        return value ? (
                            <ContentText style={{ color: 'rgb(66, 139, 247)' }} text={'인증완료'} />
                        ) : item.license_number ? (
                            <MainButton text={'인증요청'} style={{ backgroundColor: 'rgb(255, 16, 125)' }} onClick={onClickPayout(item.user_personal_account_id, item.account_bank, item.account_no)} />
                        ) : (
                            '-'
                        );

                    return value || '-';
                }}
            />
        </div>
    );
};

export default GatheringAccountInfo;
