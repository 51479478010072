import {
    AlertTwoTone,
    ApartmentOutlined,
    BarChartOutlined,
    CalendarOutlined,
    CheckSquareTwoTone,
    CrownTwoTone,
    DollarTwoTone,
    DoubleLeftOutlined,
    EditTwoTone,
    FundViewOutlined,
    IdcardTwoTone,
    HeartTwoTone,
    HighlightTwoTone,
    LineChartOutlined,
    MailTwoTone,
    MessageOutlined,
    OrderedListOutlined,
    UserOutlined,
    VideoCameraTwoTone,
    GiftTwoTone,
    PhoneTwoTone,
    MessageTwoTone,
    LockOutlined,
    DatabaseOutlined,
} from '@ant-design/icons';

// 이용자관리
import User from '../pages/user/User'; // 회원
import Administrator from '../pages/administrator/Administrator'; // 관리자
import Report from '../pages/report/Report'; // 신고
import AllPayment from '../pages/allPayment/AllPayment'; // 전체결제
import MyPage from '../pages/myPage/myPage'; // 마이페이지

// 단짝맺기관리
import SyrupManagement from '../pages/syrup/SyrupManagement'; // 시럽관리
import Chatting from '../pages/chatting/Chatting'; // 단짝 채팅
import Letter from '../pages/letter/Letter'; // 편지
import CallHistory from '../pages/callHistory/CallHistory'; // 영상통화내역

// 모임관리
import Gathering from '../pages/gathering/Gathering'; // 모임
import AddGathering from '../pages/gathering/AddGathering'; // 모임 > 모임추가
import GatheringDetail from '../pages/gathering/GatheringDetail'; // 모임 > 모임상세
import GatheringContents from '../pages/gathering/GatheringContents'; // 게시글
import GatheringPickContents from '../pages/gatheringSinorPick/GatheringPickContentsPage'; // 시놀 PICK 게시글
import GatheringAllChat from '../pages/gathering/GatheringAllChat'; // 모임 단체 채팅
import GatheringChat from '../pages/gathering/GatheringChat'; // 모임 채팅
import GatheringSchedule from '../pages/gathering/GatheringSchedule'; // 일정
import GatheringAccount from '../pages/gathering/GatheringAccount'; // 계좌관리
import GatheringAccountInfoDetail from '../pages/gathering/GatheringAccountInfoDetail'; // 계좌 상세 사용내역

// 취미/여가관리
import ActivityPayment from '../pages/activityPayment/ActivityPayment'; // 취미/여가결제
import Host from '../pages/host/Host'; // 호스트
import Activity from '../pages/activity/Activity'; // 취미/여가

// 상품관리
import CummersLists from '../pages/cummers/cummersLists';
import CummersHost from '../pages/cummers/cummersHost';
import OrderLists from '../pages/cummers/orderLists';
import ReturnGoods from '../pages/cummers/returnGoods';
import Detail from '../pages/cummers/productDetailPage';
import AddProduct from '../pages/cummers/addProduct';

// 운영관리
import SmsPage from '../pages/message/SmsPage'; // SMS 발송
import StatisticsPage from '../pages/statistics/StatisticsPage'; // 통계
import Marketing from '../pages/marketing/Marketing'; // 마케팅
import ClickPercentage from '../pages/clickPercentage/clickPercentage'; // 클릭률
import CustomerData from '../pages/customerData/customerData'; // 고객데이터

// 혜택관리
import Point from '../pages/point/Point'; // 포인트
import Event from '../pages/event/Event'; // 이벤트
import Announcement from '../pages/announcement/Announcement'; // 공지
import Survey from '../pages/survey/Survey'; // 설문관리
import Question from '../pages/question/Question'; // 질문관리

// Customer Service
import CustomerService from '../pages/customerService/CustomerService';
import CustomerStatistics from '../pages/customerService/CustomerStatistics';

// 알림톡
import AlarmTalk from '../pages/alarmTalk/AlarmTalk';
import AlarmTalkResult from '../pages/alarmTalk/AlarmTalkResult';
import AlarmTalkTemplate from '../pages/alarmTalk/AlarmTalkTemplate';

// 푸쉬 알람
import PushAlarm from '../pages/pushAlarm/PushAlarm';
import OrderListsDetail from '../pages/cummers/orderListsDetail';
import SurveyDetail from '../pages/survey/SurveyDetail';
import RecommendHistory from '../pages/recommendHistory/RecommendHistory';
import PolicyTable from '../components/PolicyComponent/PolicyTable';
import GatheringRank from '../pages/gathering/GatheringRank';
import CreateCSHistory from '../EventComponents/Cs/createCSHistory';
import AuthNumberHistory from '../pages/AuthNumberHistory';
import TourHistory from '../pages/TourHistory';

const development = !true;
const sidebarData = [
    {
        mainTitle: '이용자관리',
        subTitleList: [
            {
                subId: 0,
                icon: <UserOutlined style={{ color: 'rgb(66,139,247)' }} />,
                name: '회원',
                router_url: '/home',
                components: <User />,
            },
            {
                subId: 1,
                icon: <LockOutlined style={{ color: 'rgb(66,139,247)' }} />,
                name: '인증번호',
                router_url: '/authNumber',
                components: <AuthNumberHistory />,
            },
            {
                subId: 2,
                icon: <CrownTwoTone twoToneColor={'rgb(247 144 66)'} />,
                name: '관리자',
                router_url: '/administrator',
                components: <Administrator />,
            },
            {
                subId: 3,
                icon: <AlertTwoTone twoToneColor={'red'} />,
                name: '신고',
                router_url: '/report',
                components: <Report />,
            },
            {
                subId: 4,
                icon: <MailTwoTone twoToneColor={'rgb(78 70 255)'} />,
                name: 'SMS 발송',
                router_url: '/sms',
                components: <SmsPage />,
            },
            {
                subId: 5,
                icon: <DollarTwoTone />,
                name: '전체 결제',
                router_url: '/allPayments',
                components: <AllPayment />,
            },
            {
                subId: 6,
                name: '관리자 마이페이지',
                router_url: '/myPage/:id',
                components: <MyPage />,
                blur: true,
            },
        ],
    },
    {
        mainTitle: '대시보드',
        subTitleList: [
            {
                subId: 0,
                icon: <LineChartOutlined style={{ color: 'red' }} />,
                name: '전체 통계',
                router_url: '/statistics',
                components: <StatisticsPage />,
            },
            {
                subId: 1,
                icon: <FundViewOutlined style={{ color: 'red' }} />,
                name: '마케팅',
                router_url: '/marketing',
                components: <Marketing />,
            },
            {
                subId: 2,
                icon: <BarChartOutlined style={{ color: 'red' }} />,
                name: '클릭률(상세유입경로)',
                router_url: '/clickPercentage',
                components: <ClickPercentage />,
            },
            {
                subId: 3,
                icon: <LineChartOutlined style={{ color: 'red' }} />,
                name: '고객데이터',
                router_url: '/customerData',
                components: <CustomerData />,
            },
            {
                subId: 4,
                icon: <LineChartOutlined style={{ color: 'red' }} />,
                name: '여행 데이터',
                router_url: '/tourHistory',
                components: <TourHistory />,
            },
        ],
    },
    {
        mainTitle: '모임 관리',
        subTitleList: [
            {
                subId: 0,
                icon: <ApartmentOutlined style={{ color: 'rgb(73 118 184)' }} />,
                name: '모임',
                router_url: '/gathering',
                components: <Gathering />,
            },
            {
                subId: 1,
                icon: <CalendarOutlined style={{ color: 'rgb(123 189 141)' }} />,
                name: '일정',
                router_url: '/gathering/schedule',
                components: <GatheringSchedule />,
            },
            {
                subId: 2,
                icon: <HighlightTwoTone twoToneColor={'rgb(7 141 142)'} />,
                name: '게시글',
                router_url: '/gathering/contents',
                components: <GatheringContents />,
            },
            {
                subId: 3,
                icon: <CheckSquareTwoTone twoToneColor={'rgb(23 199 30)'} />,
                name: '시놀 PICK 게시글',
                router_url: '/gathering/pick/contents',
                components: <GatheringPickContents />,
            },
            {
                subId: 4,
                icon: <MessageOutlined style={{ color: 'blue' }} />,
                name: '모임 단체 대화방',
                router_url: '/gathering/allChat',
                components: <GatheringAllChat />,
            },
            {
                subId: 5,
                icon: <MessageOutlined style={{ color: 'green' }} />,
                name: '모임 대화방',
                router_url: '/gathering/chat',
                components: <GatheringChat />,
            },
            {
                subId: 6,
                name: '모임 상세',
                router_url: '/gathering/detail/:gathering_id',
                components: <GatheringDetail />,
                blur: true,
            },
            {
                subId: 7,
                name: '모임 등록',
                router_url: '/gathering/addGathering',
                components: <AddGathering />,
                blur: true,
            },

            {
                subId: 8,
                icon: <DollarTwoTone twoToneColor={'rgb(40 184 85)'} />,
                name: '계좌 관리',
                router_url: '/gathering/account',
                components: <GatheringAccount />,
            },
            {
                subId: 9,
                name: '계좌 상세 사용내역',
                router_url: '/gathering/accountInfo/:gathering_id',
                components: <GatheringAccountInfoDetail />,
                blur: true,
            },
            {
                subId: 10,
                icon: <BarChartOutlined style={{ color: 'red' }} />,
                name: '순위',
                router_url: '/gathering/rank',
                components: <GatheringRank />,
            },
        ],
    },
    {
        mainTitle: '단짝관리',
        subTitleList: [
            {
                subId: 0,
                icon: <HeartTwoTone twoToneColor={'#eb2f96'} />,
                name: '하트 관리',
                router_url: '/syrup',
                components: <SyrupManagement />,
            },
            {
                subId: 1,
                icon: <EditTwoTone twoToneColor={''} />,
                name: '편지',
                router_url: '/letter',
                components: <Letter />,
            },
            {
                subId: 2,
                icon: <MessageOutlined style={{ color: 'green' }} />,
                name: '단짝 대화',
                router_url: '/chatting',
                components: <Chatting />,
            },
            {
                subId: 3,
                icon: <VideoCameraTwoTone />,
                name: '영상통화내역',
                router_url: '/callHistoy',
                components: <CallHistory />,
            },
            {
                subId: 3,
                icon: <HeartTwoTone twoToneColor={'red'} />,
                name: '단짝 추천 내역',
                router_url: '/recommendFriends',
                components: <RecommendHistory />,
            },
        ],
    },
    {
        mainTitle: '상품 관리',
        productPage: 1,
        subTitleList: [
            {
                subId: 0,
                icon: <GiftTwoTone twoToneColor={'rgb(216 48 164)'} />,
                name: '상품리스트',
                router_url: '/goodsLists',
                components: <CummersLists />,
            },
            {
                subId: 1,
                icon: <OrderedListOutlined style={{ color: 'rgb(133 92 219)' }} />,
                name: '주문관리',
                router_url: '/orderLists',
                components: <OrderLists />,
            },
            {
                subId: 2,
                icon: <DoubleLeftOutlined style={{ color: 'rgb(16 105 88)' }} />,
                name: '반품관리',
                router_url: '/return',
                components: <ReturnGoods />,
                blur: true,
            },
            {
                subId: 3,
                icon: <IdcardTwoTone />,
                name: '판매자관리',
                router_url: '/goodsHost',
                components: <CummersHost />,
            },
            {
                subId: 4,
                name: '상품추가',
                router_url: '/addProduct',
                components: <AddProduct />,
                blur: true,
            },
            {
                subId: 5,
                name: '상품상세',
                router_url: '/product/:id/:host_id',
                components: <Detail />,
                blur: true,
            },
            {
                subId: 6,
                name: '주문상세',
                router_url: '/order/:order_id',
                components: <OrderListsDetail />,
                blur: true,
            },
        ],
    },
    {
        mainTitle: '고객서비스',
        blur: development,
        subTitleList: [
            {
                subId: 0,
                icon: <DatabaseOutlined />,
                name: '고객 응대',
                router_url: '/cs',
                components: <CustomerService />,
            },
            {
                subId: 1,
                icon: <PhoneTwoTone twoToneColor={'rgb(66,139,247)'} />,
                name: '전화 내역 생성',
                router_url: '/cs/callHistory',
                components: <CreateCSHistory />,
            },
            {
                subId: 2,
                icon: <LineChartOutlined style={{ color: 'red' }} />,
                name: 'CS 통계',
                router_url: '/cs/statistics',
                components: <CustomerStatistics />,
            },
        ],
    },
    {
        mainTitle: '웹 푸시',
        blur: development,
        subTitleList: [
            {
                subId: 1,
                icon: <MessageOutlined style={{ color: 'green' }} />,
                name: '푸시 알람',
                router_url: '/pushAlarm',
                components: <PushAlarm />,
            },
        ],
    },
    {
        mainTitle: '알림톡',
        blur: development,
        subTitleList: [
            {
                subId: 1,
                icon: <MessageTwoTone twoToneColor={'rgb(247 144 66)'} />,
                name: '알림톡 전송',
                router_url: '/alarmTalk',
                components: <AlarmTalk />,
            },
            {
                subId: 2,
                icon: <MessageTwoTone twoToneColor={'rgb(247 144 66)'} />,
                name: '알림톡 전송 내역',
                router_url: '/alarmTalk/result',
                components: <AlarmTalkResult />,
            },
            {
                subId: 3,
                icon: <MessageTwoTone twoToneColor={'rgb(247 144 66)'} />,
                name: '알림톡 템플릿',
                router_url: '/alarmTalk/template',
                components: <AlarmTalkTemplate />,
            },
        ],
    },
    {
        mainTitle: '혜택관리',
        subTitleList: [
            {
                subId: 0,
                icon: <span style={{ color: 'rgb(98 125 222)' }}>P</span>,
                name: '포인트',
                router_url: '/point',
                components: <Point />,
            },
            {
                subId: 2,
                icon: <span style={{ color: 'rgb(98 125 222)' }}>E</span>,
                name: 'Banner',
                router_url: '/event',
                components: <Event />,
            },
            {
                subId: 3,
                icon: <span style={{ color: 'rgb(98 125 222)' }}>A</span>,
                name: '공지',
                router_url: '/announcement',
                components: <Announcement />,
            },
            {
                subId: 4,
                icon: <span style={{ color: 'rgb(98 125 222)' }}>S</span>,
                name: '설문관리',
                router_url: '/survey/management',
                components: <Survey />,
            },
            {
                subId: 5,
                icon: <span style={{ color: 'rgb(98 125 222)' }}>Q</span>,
                name: '질문관리',
                router_url: '/question/management',
                components: <Question />,
            },
            {
                subId: 6,
                name: '질문상세',
                router_url: '/survey/management/detail/:survey_id',
                components: <SurveyDetail />,
                blur: true,
            },
            {
                subId: 7,
                icon: <span style={{ color: 'rgb(98 125 222)' }}>P</span>,
                name: '정책관리',
                router_url: '/policy/management',
                components: <PolicyTable />,
            },
        ],
    },
    {
        mainTitle: '취미/여가 관리',
        blur: true,
        subTitleList: [
            {
                subId: 0,
                name: '취미/여가 결제',
                router_url: '/activityPayment',
                components: <ActivityPayment />,
            },
            {
                subId: 1,
                name: '호스트',
                router_url: '/host',
                components: <Host />,
            },
            {
                subId: 2,
                name: '취미/여가',
                router_url: '/activity',
                components: <Activity />,
            },
        ],
    },
];

export { sidebarData };
