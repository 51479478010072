import { useState, useCallback, useRef } from 'react';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import ContentDetailModal from '../../designComponents/modals/ContentDetailModal';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import { HoverSpan } from '../../designComponents/tables/TbodyLists';

import { GatheringReportFilter } from './GatheringReportFilter';
import { contentFilter, dateFilter } from '../../utils/textFilter';
import { adminUsers } from '../../dataSystems/AdminUserLists';
import TableFilter from '../../designComponents/filters/TableFilter';
import UserDetailModal from '../UserComponents/UserDetailModal';

/**  신고 페이지 */
const ContentsReportPage = () => {
    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({});

    const [contentDetailToggle, setContentDetailToggle] = useState(false);
    const [contentDetailId, setContentDetailId] = useState(0);

    const ref = useRef();
    const tableRef = useRef();

    // 회원 상세정보 보기
    const onClickUserInfoModal = (userId) => () => {
        setUserDetailToggle({ toggle: true, userId: userId });
    };

    // 신고받은 게시글 상세보기
    const onClickContentDetail = (content_id) => () => {
        setContentDetailId(content_id);
        setContentDetailToggle(true);
    };

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminReport/gathering`, { params: input });
        return response.data.body;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminReport/gathering`, { params: input });
        return response.data.all_count;
    };

    return (
        <div>
            {userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}
            {contentDetailToggle && <ContentDetailModal id={contentDetailId} setToggle={setContentDetailToggle} />}
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '신고자 닉네임', value: 1 },
                    { id: 2, title: '신고자 이름', value: 2 },
                    { id: 3, title: '신고자 휴대폰', value: 3 },
                    { id: 6, title: '신고자 유저 ID', value: 6 },
                    { id: 1, title: '피 신고자 닉네임', value: 7 },
                    { id: 2, title: '피 신고자 이름', value: 8 },
                    { id: 3, title: '피 신고자 휴대폰', value: 9 },
                    { id: 4, title: '게시글 제목', value: 4 },
                    { id: 5, title: '게시글 내용', value: 5 },

                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    wrapperStyle={{ width: '95%' }}
                    arrFilterSt={{ flexDirection: 'column', alignItems: 'start' }}
                    param={param}
                    setParam={setParam}
                    topFilterLists={[
                        {
                            filterText: '순서정렬 필터',
                            orderLists: [
                                { name: '최신순', width: 50, type: 1 },
                                { name: '오래된순', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.created_at = type;
                                else delete obj.created_at;
                                setParam(obj);
                            },
                            orderbyType: param.created_at,
                        },
                    ]}
                    filterLists={GatheringReportFilter}
                    research={ref}
                />
            </div>
            <MainTable
                ref={ref}
                tableRef={tableRef}
                toggleBlur={true}
                loadingText={'게시글 신고 내역을 불러오고 있습니다.'}
                headerData={GatheringReportFilter}
                checkID={'pay_id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {}}
                adminCheck={(item) => adminUsers.includes(item.phone_no) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'user_name') return <HoverSpan onClick={onClickUserInfoModal(item.user_id)}>{value}</HoverSpan>;
                    if (key === 'user_nickname') return <HoverSpan onClick={onClickUserInfoModal(item.user_id)}>{value}</HoverSpan>;
                    if (key === 'content_title') return <HoverSpan onClick={onClickContentDetail(item.content_id)}>{contentFilter(value, 15)}</HoverSpan>;
                    if (key === 'content') return <HoverSpan onClick={onClickContentDetail(item.content_id)}>{contentFilter(value, 15)}</HoverSpan>;
                    return value;
                }}
            />
        </div>
    );
};

export default ContentsReportPage;
