import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableFilter from '../../designComponents/filters/TableFilter';
import { userTableFilter } from '../../components/UserComponents/UserTableFilter';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { adminUsers } from '../../dataSystems/AdminUserLists';
import { availCheck, colorFilter, contentFilter, dateFilter, noneImage, profilePhotoAuth, sexFilter } from '../../utils/textFilter';
import { Image } from '../../designComponents/imageboxs/Image';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { useNavigate } from 'react-router-dom';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import UserDetailModal from '../../components/UserComponents/UserDetailModal';
import BatchUpdateModal from '../../components/UserComponents/BatchUpdateModal';
import { ImageModal } from '../../designComponents/modals/ModalComponent';

const SinorLoveUserTable = ({}) => {
    const [batchWorkToggle, setBatchWorkToggle] = useState(false);
    const [userDetailToggle, setUserDetailToggle] = useState(false);
    const [imageToggle, setImageToggle] = useState(false);
    const [checkItems, setCheckItems] = useState([]); // 체크된 회원id를 담을 배열
    const [checkAllData, setCheckAllData] = useState([]); // 체크된 회원data를 담을 배열
    const [src, setSrc] = useState('');
    const [sex, setSex] = useState(1);

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        platform: 1,
        orderType: 1,
        orderByType: 5,
    });

    const ref = useRef(null);
    const imgRef = useRef(null);
    const tableRef = useRef(null);

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 0,
            ...param,
        };
        const response = await RefreshCheckApi.post('/adminUser/list/sinorLove', input);
        if (response.status === 200) return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 0,
            ...param,
        };
        const response = await RefreshCheckApi.post('/adminUser/list/sinorLove', input);
        if (response.status === 200) return response?.data?.all_count;
    };

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle((prev) => !prev);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const onClickImage = (item, value) => {
        setSex(item.sex);
        setImageToggle(true);
        setSrc(value);
    };

    // 선택 회원 일괄 작업
    const AllUserControll = (checkItems, checkAllData) => () => {
        setCheckItems(checkItems);
        setCheckAllData(checkAllData);
        if (checkItems.length > 0) setBatchWorkToggle(true);
        else alert('선택된 회원이 없습니다.');
    };

    /** 일괄작업 및 최근 한달 접속자 필터 */
    const TopButtonFilter = ({ onClick = () => {} }) => {
        return (
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start' }}>
                <TopSideButton middleOn={false} rightOn={false} leftWidth={90} text01={'일괄 작업'} onClick={onClick} />
            </FlexMainWrapper>
        );
    };

    return (
        <>
            {userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} updateLists={() => ref?.current?.refresh()} />}
            {batchWorkToggle && <BatchUpdateModal checkItems={checkItems} checkAllData={checkAllData} setBatchWorkToggle={setBatchWorkToggle} />}
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={src} errImg={noneImage(sex)} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}

            <SearchbarWithDropdown
                style={{ margin: '25px 0' }}
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '회원ID', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    if (searchType === 1) {
                        setSearchText(searchText.replace(/-/g, ''));
                    }
                    ref?.current?.refresh();
                }}
                searchText={searchType === 1 ? searchText.replace(/-/g, '') : searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
                research={ref}
                setSearchText={setSearchText}
            />
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    param={param}
                    setParam={setParam}
                    topFilterLists={[
                        {
                            filterText: '정렬 기준',
                            orderLists: [
                                { name: '가입일', width: 35, type: 5 },
                                { name: '이름', width: 25, type: 1 },
                                { name: '닉네임', width: 35, type: 2 },
                                { name: '나이', width: 25, type: 3 },
                                { name: '생년월일', width: 45, type: 4 },
                                { name: '최근 접속일', width: 80, type: 6 },
                                { name: '프로필 사진 최신', width: 90, type: 7 },
                                { name: '카메라 인증 최신', width: 90, type: 8 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked)
                                    obj.orderByType = type; // setOrderbyType(type);
                                else delete obj.orderByType;
                                setParam(obj);
                            },
                            orderbyType: param.orderByType,
                        },
                        {
                            filterText: '정렬 방식',
                            orderLists: [
                                { name: '내림 차순', width: 50, type: 1 },
                                { name: '오름 차순', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked)
                                    obj.orderType = type; // setOrderbyType(type);
                                else delete obj.orderType;
                                setParam(obj);
                            },
                            orderbyType: param.orderType,
                        },
                        {
                            filterText: '신고 받은 횟수',
                            orderLists: [
                                { name: '3회 미만', width: 50, type: 1 },
                                { name: '3회 이상', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.reportCntType = type;
                                else delete obj.reportCntType;
                                setParam(obj);
                            },
                            orderbyType: param.reportCntType,
                        },

                        {
                            filterText: '성별',
                            orderLists: [
                                { name: '남', width: 50, type: 1 },
                                { name: '여', width: 50, type: 2 },
                                { name: 'NULL', width: 50, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.sexType = type;
                                else delete obj.sexType;
                                setParam(obj);
                            },
                            orderbyType: param.sexType,
                        },
                        {
                            filterText: '로그인',
                            orderLists: [
                                { name: '카카오', width: 50, type: 1 },
                                { name: '핸드폰', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.loginType = type;
                                else delete obj.loginType;
                                setParam(obj);
                            },
                            orderbyType: param.loginType,
                        },
                        {
                            filterText: '마케팅 동의',
                            orderLists: [
                                { name: '동의', width: 50, type: 1 },
                                { name: '미동의', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.marketingType = type;
                                else delete obj.marketingType;
                                setParam(obj);
                            },
                            orderbyType: param.marketingType,
                        },
                        {
                            filterText: '이용 상태',
                            orderLists: [
                                { name: '정상', width: 50, type: 1 },
                                { name: '탈퇴', width: 50, type: 2 },
                                { name: '이용 차단', width: 50, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.availType = type;
                                else delete obj.availType;
                                setParam(obj);
                            },
                            orderbyType: param.availType,
                        },
                        {
                            filterText: '프로필 사진',
                            orderLists: [
                                { name: '사진 있음', width: 50, type: 1 },
                                { name: '사진 없음', width: 50, type: 2 },
                                { name: '카카오 프로필', width: 100, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.profileType = type;
                                else delete obj.profileType;
                                setParam(obj);
                            },
                            orderbyType: param.profileType,
                        },
                        {
                            filterText: '카메라 인증',
                            orderLists: [
                                { name: '여', width: 50, type: 1 },
                                { name: '부', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.authType = type;
                                else delete obj.authType;
                                setParam(obj);
                            },
                            orderbyType: param.authType,
                        },
                        {
                            filterText: '카메라 인증 결과',
                            orderLists: [
                                { name: '인증완료', width: 50, type: 1 },
                                { name: '인증 대기중', width: 50, type: 2 },
                                { name: '미인증', width: 50, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.authResultType = type;
                                else delete obj.authResultType;
                                setParam(obj);
                            },
                            orderbyType: param.authResultType,
                        },
                        {
                            filterText: '접속 기간',
                            orderLists: [{ name: '한 달', width: 50, type: 1 }],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.recentType = type;
                                else delete obj.recentType;
                                setParam(obj);
                            },
                            orderbyType: param.recentType,
                        },
                    ]}
                    filterLists={[]}
                    research={ref}
                />
            </div>
            <MainTable
                scrollWidth={190}
                toggleBlur={true}
                buttonComponents={({ checkItems, checkDatas }) => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', gap: '20px' }}>
                        <TopButtonFilter onClick={AllUserControll(checkItems, checkDatas)} />
                    </FlexMainWrapper>
                )}
                ref={ref}
                tableRef={tableRef}
                loadingText={'회원 리스트를 불러오고 있습니다.'}
                headerData={userTableFilter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item, key) => {
                    if (key !== 'profile_image_list' && key !== 'auth_image') {
                        return () => setUserDetailToggle({ toggle: true, userId: item.id });
                    }
                }}
                adminCheck={(item) => adminUsers.includes(item.id) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    const nullText = <span style={{ color: '#797979' }}>정보없음</span>;
                    const redText = (text) => <span style={{ color: 'red' }}>{text}</span>;
                    if (key === 'nick_name') return contentFilter(item['nick_name'], 10) || nullText;
                    if (key === 'sex') return sexFilter(value);
                    if (key === 'kakao_yn') return !value ? '휴대폰' : <span style={{ color: '#a4a42c' }}>카카오</span>;
                    if (key === 'report_count') return !value ? '0회' : `${value}회`;

                    // if (key === 'love_marketing_yn') return colorFilter(value === 1, value === 1 ? '동의' : '미동의');
                    if (key === 'marketing_yn') return colorFilter(value === 1, value === 1 ? '동의' : '미동의');
                    if (key === 'service_agree_yn') return colorFilter(value === 1, value === 1 ? '동의' : '미동의');
                    if (key === 'recommend_yn') return value === 1 ? '공개' : redText('비공개');
                    if (key === 'avail_yn' || key === 'blocked_yn') return availCheck(item.blocked_yn, item.avail_yn);
                    if (key === 'user_created_at') return dateFilter(value);
                    if (key === 'recent_login_date') return dateFilter(value);
                    if (key === 'profile_photo_auth_yn') return profilePhotoAuth(value);
                    if (key === 'profile') {
                        return (
                            <span onClick={() => onClickImage(item, value)}>
                                <Image size="S80" url={value} errImg={noneImage(item.sex)} width={80} height={80} alt="회원 이미지" style={{ borderRadius: '12%', paddingTop: '4%' }} />
                            </span>
                        );
                    }
                    if (key === 'auth_image')
                        return (
                            <span onClick={() => onClickImage(item, value)}>
                                <Image size="S80" url={value} errImg={noneImage(item.sex)} width={80} height={80} alt="인증 이미지" style={{ borderRadius: '12%', paddingTop: '4%' }} />
                            </span>
                        );
                    return value;
                }}
            />
        </>
    );
};

export default SinorLoveUserTable;
