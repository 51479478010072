import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import { LText, MText } from '../../designComponents/text/ContentText';
import LoadingPage from '../../pages/loading/LoadingPage';
import { Image } from '../../designComponents/imageboxs/Image';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { MultipleTitleCheckBoxInput, TitleCheckboxInput } from '../../designComponents/checkboxs/CheckboxInput';
import { dateFilter, gatheringBankInfoFilter, interestWordFilter } from '../../utils/textFilter';
import { TextArea } from '../../designComponents/inputs/TextArea';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import AddGatheringChooseInterestModal from '../../designComponents/modals/AddGatheringChooseInterestModal';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import UserDetailModal from '../UserComponents/UserDetailModal';
import { LocationSelectModal } from '../../GroupComponent/Modal/LocationSelectModal';
import { MiddleModal } from '../../common/BootStrap/Modal';
import DraggableImageGrid from '../../GroupComponent/Grid/DraggableImageGrid';
import InterestSelector, { InterestCodeType } from '../Common/InterestSelector';

const GatheringDetailComponent = ({ gathering_id, questions, gatheringList, gatheringCrewList, pastOwners, loading, research = () => {} }) => {
    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');
    const [showGatheringInterest, setShowGatheringInterest] = useState(false);
    const [toggle, setToggle] = useState({ interestToggle: false });
    const [updateParam, setUpdateParam] = useState({ ...gatheringList });
    const [addressModal, setAddressModal] = useState(false);
    const [image, setImageList] = useState([]);
    const [question, setQuestion] = useState(questions);
    const imgRef = useRef(null);
    useEffect(() => {
        setQuestion(questions);
    }, [questions]);
    useEffect(() => {
        if (gatheringList) {
            console.log(gatheringList);
            setUpdateParam({
                ...gatheringList,
                cost_pay_out: gatheringList?.id === 479 || gatheringList?.id === 480 ? 10 : 3.5,
                interest_code: gatheringList?.interest_code?.length ? [...gatheringList?.interest_code] : [],
            });
            setImageList(gatheringList.gathering_image_lists);
        }
    }, [gatheringList]);

    const onChangeParam = (key, value) => {
        let obj = { ...updateParam };
        obj[key] = value;
        setUpdateParam(obj);
    };

    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });
        const response = await RefreshCheckApi.post('/adminGathering/uploadImage', imageFormData);
        setTimeout(() => {
            console.log(response.data);
            // let obj = { ...updateParam };
            // obj.gathering_image_lists = response.data;
            // setUpdateParam(obj);
            setImageList([...image, ...response.data]);
            e.target.value = '';
        }, 1100);
    };

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle((prev) => !prev);
        }
    };
    const saveGathering = async () => {
        try {
            if (image.length === 0) return alert('모임 대표사진을 1장 이상 추가해 주세요');
            if (!updateParam.interest_code.length) return alert('관심 주제를 선택해 주세요.');
            let params = {
                ...updateParam,
                gathering_id: gathering_id, // 모임 ID
                interest: updateParam.interest_code.map((e) => e.interest_code), // 관심주제
                address: updateParam.address, // 활동지역
                gathering_name: updateParam.gathering_name, // 모임명
                gatheringExplanation: updateParam.explanation,
                gathering_image_lists: image, // 모임이미지
                updated_at: moment().subtract(9, 'hour').format('YYYY-MM-DD HH:MM:SS'), // 업데이트일
                deleted_at: updateParam.deleted_at, // 폐쇄일
                exposure_yn: updateParam.exposure_yn, // 노출여부
                purpose: updateParam.purpose,
            };
            if (updateParam.registration_type === 1) {
                params.registration_question = question;
            }
            const response = await RefreshCheckApi.patch('/adminGathering', params);
            if (response.status === 200) {
                alert('모임정보 업데이트를 완료했습니다.');
                research();
            }
        } catch (error) {
            console.error(error);
            alert('[Error] 서버관리자에게 문의하세요.');
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // 회원상세모달
    const onClickUserModal = (user_id) => () => {
        setUserDetailToggle({ toggle: true, userId: user_id });
    };

    const nullTag = (text) => <span style={{ color: '#797979' }}>{text}</span>;

    if (loading) return <LoadingPage text={'모임정보를 불러오고 있습니다.'} />;
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', gap: 3 }}>
            <LocationSelectModal
                visible={addressModal}
                setVisible={setAddressModal}
                initValue={gatheringList?.address}
                onChange={(value) => {
                    let obj = { ...updateParam };
                    obj.address = value;
                    setUpdateParam(obj);
                }}
            />

            {userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}
            <InterestSelector
                withCodeName
                visible={showGatheringInterest}
                setVisible={setShowGatheringInterest}
                codeType={InterestCodeType.GatheringInterest}
                activeCodes={updateParam.interest_code.map((e) => e.interest_code)}
                maxCount={1}
                onSave={(codes) => {
                    setUpdateParam((pre) => ({ ...pre, interest_code: codes.map((e) => ({ code_name: e.code_name, interest_code: e.code })) }));
                    setShowGatheringInterest(false);
                }}
                // kategoryLists={updateParam.interest_code}
                // onClickCloseModal={() => setToggle({ interestToggle: false })}
                // onClickInterests={(checked, key, array) => {
                //     let obj = { ...updateParam };
                //     obj.interest_code = array;
                //     setUpdateParam(obj);
                // }}
            />
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={src} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}

            <Wrapper03 style={{ width: '100%' }}>
                <FlexMainWrapper style={{ flexDirection: 'column' }}>
                    <FlexMainWrapper style={{ justifyContent: 'space-between', width: '100%' }}>
                        <LText text={'모임 정보'} />
                        <MainButton text="저장 하기" onClick={saveGathering} />
                    </FlexMainWrapper>

                    <FlexMainWrapper style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', minHeight: '200px', height: '300px' }}>
                        <Image
                            url={gatheringList?.gathering_image}
                            alt="모임 이미지"
                            width={150}
                            height={150}
                            style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }}
                            onClick={() => {
                                setImageToggle(true);
                                setSrc(gatheringList?.gathering_image);
                            }}
                        />
                        <FlexMainWrapper style={{ flexDirection: 'column', width: '30%', height: '100%', justifyContent: 'start' }}>
                            <InputLine title={'모임 ID'} value={gatheringList?.id ?? null} disabled={true} wrapperStyle={{}} />
                            <InputLine
                                buttonOn
                                title={'관심 주제ㄴ'}
                                value={updateParam?.interest_code.map((e) => e.code_name).join() ?? ''}
                                disabled={true}
                                titleStyle={{ width: '100px' }}
                                inputStyle={{ width: '200px' }}
                                btnText={'선택'}
                                onClick={() => {
                                    setShowGatheringInterest(true);
                                }}
                            />
                            <InputLine
                                buttonOn
                                title={'활동 지역'}
                                value={updateParam?.address ?? ''}
                                disabled={true}
                                titleStyle={{ width: '100px' }}
                                inputStyle={{ width: '200px' }}
                                btnText={'검색'}
                                onClick={() => {
                                    setAddressModal(true);
                                }}
                            />
                            <InputLine title={'가입회원 수'} value={`${gatheringList?.count_crew || 0}명`} disabled={true} wrapperStyle={{}} />
                            <TitleCheckboxInput
                                titleStyle={{ color: '#797979', width: '80px' }}
                                exposure
                                text={'검색 노출'}
                                text01={'ON'}
                                text02={'OFF'}
                                checked01={updateParam.exposure_yn === 1}
                                checked02={updateParam.exposure_yn === 0}
                                onChange01={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) onChangeParam('exposure_yn', 1);
                                }}
                                onChange02={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) onChangeParam('exposure_yn', 0);
                                }}
                            />
                            <TitleCheckboxInput
                                titleStyle={{ color: '#797979', width: '80px' }}
                                exposure
                                text={'모임 형태'}
                                text01={'친목'}
                                text02={'배움'}
                                checked01={updateParam.purpose === 0}
                                checked02={updateParam.purpose === 1}
                                onChange01={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) onChangeParam('purpose', 0);
                                }}
                                onChange02={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) onChangeParam('purpose', 1);
                                }}
                            />
                        </FlexMainWrapper>

                        <FlexMainWrapper style={{ flexDirection: 'column', width: '30%', height: '100%', justifyContent: 'start' }}>
                            <InputLine
                                title={'모임명'}
                                value={updateParam?.gathering_name ?? ''}
                                onChange={(e) => onChangeParam('gathering_name', e.target.value)}
                                placeholder={gatheringList.gathering_name || '모임명을 입력하세요'}
                                wrapperStyle={{ padding: 0 }}
                            />
                            <InputLine title={'개설일'} value={dateFilter(gatheringList?.created_at) ?? ''} disabled={true} wrapperStyle={{}} />
                            <InputLine title={'폐쇄일'} value={gatheringList?.deleted_at || 'PROGRESS'} disabled={true} wrapperStyle={{}} />
                            <InputLine
                                title={'수수료'}
                                value={updateParam.cost_pay_out ?? ''}
                                type="number"
                                onChange={(e) => onChangeParam('cost_pay_out', e.target.value)}
                                placeholder={gatheringList.cost_pay_out || '수수료 값을 입력하세요'}
                            />
                            <TitleCheckboxInput
                                disabled
                                titleStyle={{ color: '#797979', width: '80px' }}
                                exposure
                                text={'계좌 인증'}
                                text01={'인증 완료'}
                                text02={'인증 미완료'}
                                checked01={updateParam.auth_yn === 0}
                                checked02={updateParam.auth_yn === 1}
                                onChange01={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) onChangeParam('auth_yn', 1);
                                }}
                                onChange02={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) onChangeParam('auth_yn', 0);
                                }}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', gap: '20px', width: '100%' }}>
                        <MText text={'가입 형태'} />
                        <MultipleTitleCheckBoxInput
                            onChange={(e) => {
                                onChangeParam('registration_type', e.key);
                            }}
                            init={updateParam.registration_type}
                            data={[
                                { key: 0, value: '즉시가입' },
                                { key: 1, value: '승인 후 가입' },
                                { key: 2, value: '초대가입' },
                            ]}
                        />
                        <InputLine
                            title={'Q1.'}
                            value={question[0] ?? ''}
                            wrapperStyle={{ marginTop: 0 }}
                            disabled={updateParam?.registration_type !== 1}
                            onChange={(e) => {
                                const copy = [...question];
                                Array.from(Array(3).keys()).map((i) => {
                                    if (!copy[i] && i !== 0) {
                                        copy[i] = '';
                                    } else if (i === 0) {
                                        copy[i] = e.target.value;
                                    }
                                });

                                setQuestion(copy);
                            }}
                            placeholder={'첫번째 질문'}
                        />
                        <InputLine
                            title={'Q2.'}
                            value={question[1] ?? ''}
                            wrapperStyle={{ marginTop: 0 }}
                            disabled={updateParam?.registration_type !== 1}
                            onChange={(e) => {
                                const copy = [...question];
                                Array.from(Array(3).keys()).map((i) => {
                                    if (!copy[i] && i !== 1) {
                                        copy[i] = '';
                                    } else if (i === 1) {
                                        copy[i] = e.target.value;
                                    }
                                });

                                setQuestion(copy);
                            }}
                            placeholder={'두번째 질문'}
                        />
                        <InputLine
                            title={'Q3.'}
                            wrapperStyle={{ marginTop: 0 }}
                            value={question[2] ?? ''}
                            disabled={updateParam?.registration_type !== 1}
                            onChange={(e) => {
                                const copy = [...question];
                                Array.from(Array(3).keys()).map((i) => {
                                    if (!copy[i] && i !== 2) {
                                        copy[i] = '';
                                    } else if (i === 2) {
                                        copy[i] = e.target.value;
                                    }
                                });

                                setQuestion(copy);
                            }}
                            placeholder={'세번째 질문'}
                        />
                    </FlexMainWrapper>
                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', gap: '20px', width: '100%' }}>
                        <MText text={'모임 설명'} />
                        <TextArea value={updateParam?.explanation} onChange={(e) => onChangeParam('explanation', e.target.value)} style={{ width: '100%', height: '30vh' }} />
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <LText text={'모임 이미지'} style={{ marginTop: 20 }} />
                <FlexMainWrapper style={{ flexDirection: 'column', padding: 20 }}>
                    <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                    <MainButton text={'이미지 추가하기'} onClick={onClickAddImage} />
                    <DraggableImageGrid data={image} onImageChange={(image) => setImageList(image)} />
                    {/*{updateParam?.gathering_image_lists?.length ? (*/}
                    {/*    <FlexMainWrapper style={{ flexWrap: 'wrap', width: '350px', gap: '3px' }}>*/}
                    {/*        {updateParam?.gathering_image_lists?.map((value, index) => (*/}
                    {/*            <Image*/}
                    {/*                key={index}*/}
                    {/*                url={value}*/}
                    {/*                width={150}*/}
                    {/*                height={150}*/}
                    {/*                style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }}*/}
                    {/*                onClick={() => {*/}
                    {/*                    setImageToggle(true);*/}
                    {/*                    setSrc(value);*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        ))}*/}
                    {/*    </FlexMainWrapper>*/}
                    {/*) : (*/}
                    {/*    <Image url={null} width={150} height={150} style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }} />*/}
                    {/*)}*/}
                </FlexMainWrapper>
                <LText text={'모임장 정보'} />
                <FlexMainWrapper style={{ alignItems: 'start', gap: '20px', padding: '20px' }}>
                    <Image
                        url={updateParam.manager_profile_image}
                        width={105}
                        height={105}
                        style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }}
                        onClick={onClickUserModal(updateParam.manager_id)}
                    />
                    <FlexMainWrapper style={{ alignItems: 'start', gap: '20px' }}>
                        <FlexMainWrapper style={{ flexDirection: 'column' }}>
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'모임장명'} text02={`${updateParam.manager_name}/${updateParam.manager_nick_name}`} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'연락처'} text02={updateParam.manager_phone_no} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'계좌인증여부'} text02={updateParam.account_auth_yn} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'주민등록 이름'} text02={updateParam.license_name || nullTag('정보없음')} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'주민등록 번호'} text02={updateParam.license_number || nullTag('정보없음')} />
                        </FlexMainWrapper>

                        <FlexMainWrapper style={{ flexDirection: 'column' }}>
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'은행명'} text02={gatheringBankInfoFilter(updateParam.account_bank)} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'계좌번호'} text02={updateParam.account_no || nullTag('계좌 정보없음')} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'예금주명'} text02={updateParam.account_holder || nullTag('예금주 정보없음')} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'사업자명'} text02={updateParam.business_name || nullTag('사업자 정보없음')} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'사업자 번호'} text02={updateParam.business_number || nullTag('사업자 정보없음')} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <LText text={'운영진 정보'} />
                {gatheringCrewList.length <= 0
                    ? gatheringCrewList.map((crew, index) => (
                          <FlexMainWrapper key={index} style={{ alignItems: 'start', gap: '20px', padding: '20px' }}>
                              <Image url={crew.url} width={105} height={105} style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }} onClick={onClickUserModal(crew.id)} />
                              <FlexMainWrapper style={{ alignItems: 'start', gap: '20px' }}>
                                  <FlexMainWrapper style={{ flexDirection: 'column' }}>
                                      <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'모임장명'} text02={`${crew.name}/${crew.nick_name}`} />
                                      <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'연락처'} text02={crew.phone_no} />
                                      <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'권한'} text02={crew.role === 2 ? '운영진' : '일반'} />
                                  </FlexMainWrapper>
                              </FlexMainWrapper>
                          </FlexMainWrapper>
                      ))
                    : null}

                <LText text={'모임장 변경 내역'} />
                <FlexMainWrapper style={{ alignItems: 'center', gap: '20px', padding: '20px' }}>
                    <FlexMainWrapper style={{ alignItems: 'center', gap: '20px' }}>
                        <Image url={pastOwners?.user_profile} width={70} height={70} style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }} onClick={onClickUserModal(pastOwners?.user_id)} />
                        <FlexMainWrapper style={{ flexDirection: 'column' }}>
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'모임장명'} text02={`${pastOwners?.user_name}/${pastOwners?.user_nickname}`} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'연락처'} text02={pastOwners?.phone_no} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                    <ArrowRightOutlined />
                    <FlexMainWrapper style={{ alignItems: 'center', gap: '20px' }}>
                        <Image
                            url={pastOwners?.target_user_profile}
                            width={70}
                            height={70}
                            style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }}
                            onClick={onClickUserModal(pastOwners?.target_user_id)}
                        />
                        <FlexMainWrapper style={{ flexDirection: 'column' }}>
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'모임장명'} text02={`${pastOwners?.target_user_name}/${pastOwners?.target_user_nickname}`} />
                            <FlexTwoText gap={15} text01Style={{ width: '75px' }} text01={'연락처'} text02={pastOwners?.target_phone_no} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </Wrapper03>
        </FlexMainWrapper>
    );
};

export default GatheringDetailComponent;
